@import '../../css/variables';
@import '../../css/mixins';

body {
  main {
    margin-bottom: 0;
  }

  footer {
    margin-top: 0;
  }

  section {
    margin: 3rem 0;
  }

  h2 {
    text-transform: uppercase;
    position: relative;
    margin-left: 0.2rem;
    margin-bottom: 2rem;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      width: 2px;
      transform: rotate(15deg);
      background-color: $cigi-primary-colour;
      transform-origin: center;
    }

    &:not(:first-child) {
      margin-top: 3rem;
    }
  }

  .body {
    margin: 6rem 0 3rem;
  }

  .advisory-board {
    h2 {
      font-weight: 400;
    }

    ul {
      &.persons-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5%;
        margin: 0;

        li {
          @include link($color: $black, $hover-color: $cigi-primary-colour);
          border: 0;
          display: flex;
          padding: 0;
          margin-bottom: 1rem;

          &:nth-last-child(-n + 2) {
            margin-bottom: 0;
          }

          .person-info {
            display: block;
            margin: 0;

            .person-description {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }

  .task-forces {
    background-color: $think7_bg_grey;
    margin: 0;
    padding: 3rem 0 6rem;

    .task-forces-list {
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        gap: 5%;
      }
      display: flex;
      justify-content: space-between;

      .task-force {
        @include media-breakpoint-down(md) {
          flex-basis: 47.5%;
          margin-bottom: 2rem;
        }
        @include media-breakpoint-down(sm) {
          flex-basis: 100%;
        }
        position: relative;
        flex-basis: 24%;

        .img-wrapper {
          position: relative;
          z-index: 1;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 0.7) 50%,
              rgba(0, 0, 0, 0.1) 100%
            );
            border-radius: 15px;
          }

          img {
            @include media-breakpoint-down(sm) {
              height: 250px;
            }
            object-fit: cover;
            border-radius: 15px;
          }
        }

        .task-force-overlay {
          padding: 1.5rem 1rem;
          position: absolute;
          top: 0;
          left: 0;
          color: white;
          z-index: 2;

          .label {
            font-size: 0.8rem;
            margin-bottom: 0.75rem;
          }

          h3 {
            @include link($color: $white, $hover-color: $cigi-primary-colour);
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .latest-publication,
  .upcoming-events {
    article {
      display: flex;

      .img-wrapper {
        flex-basis: 30%;
        flex-shrink: 0;
        margin-right: 1rem;

        img {
          border-radius: 15px;
          border: 1px solid $cigi-light-grey;
        }
      }

      h3 {
        @include link($color: $black, $hover-color: $cigi-primary-colour);
        font-weight: 500;
      }

      .subtitle {
        line-height: 1.25;
      }
    }
  }

  .upcoming-events {
    .img-wrapper {
      position: relative;

      .before-wrapper {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.5) 30%,
            rgba(0, 0, 0, 0) 100%
          );
          border-radius: 15px;
        }
      }

      .img-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 1rem;
        color: $white;

        .img-overlay-date {
          font-size: 1.2rem;
          width: 50%;
          line-height: 1;
          margin-bottom: 0.2rem;
        }

        .img-overlay-city {
          @include link($color: $white, $hover-color: $cigi-primary-colour);
          i {
            margin-right: 0.2rem;
          }
        }
      }
    }

    .date {
      color: $black;
    }
  }
}
